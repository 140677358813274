<template>
  <v-app-bar
    app
    clipped-left
    clipped-right
    color="background"
    :dark="false"
    class="header mx-0 pt-5 d-flex flex-column justify-center"
    :class="[{dashboard: isDashboard}, {'v-app-bar--is-scrolled-down': scrollDirection === 'down'}]"
    :height="headerHeight"
  >
    <v-container >
      <v-row class="d-flex flex-row align-center justify-center  px-2" :class="{'flex-row-reverse': !isDashboard}">
        <v-col>
          <v-row v-if="isDashboard && !$vuetify.breakpoint.mdAndUp || !isDashboard && $vuetify.breakpoint.xs" class="mt-1 d-flex align-center flex-nowrap">
            <p v-if="isDashboard" class="logo__update mb-0 text-wrap">{{this.$store.state.lastUpdate ? 'Updated: ' + this.$store.state.lastUpdate.format('MMMM Do YYYY, HH:mm z') : '' }}</p>

            <v-spacer />

            <v-checkbox
                inset
                hide-details
                :ripple="false"
                color="text"
                large
                class="shrink switch-checkbox"
                title="Toggle dark mode"
                off-icon="$switchLight"
                on-icon="$switchDark"
                v-model="lightMode"
              ></v-checkbox>
          </v-row>
          <v-row :class="{'mt-5': isDashboard && $vuetify.breakpoint.xs}" class="header__main-info flex-column flex-nowrap">
            <v-toolbar-title  class="header__left-info align-center d-flex logo-width justify-self-center">
              <router-link :to="{ name: 'dashboard' }" title="Go to homepage" class="logo text--text  ml-auto mr-auto">
                <span class="logo__icon">
                  <img src="@/assets/images/logo.svg" class="mt-2 logo-icon" alt="" width="72" height="72"/>
                </span>
                  <h1 class="logo__title text-wrap">
                    <span class="d-none d-sm-inline">World Mobile Token Scan</span>
                    <span class="d-sm-none">WMTscan</span>
                  </h1>
              </router-link>
            </v-toolbar-title>

            <v-spacer />

            <div class="header__right-info d-flex align-center pa-0 ma-0">
              <v-switch
                v-if="$vuetify.breakpoint.mdAndUp || !isDashboard && $vuetify.breakpoint.smAndUp"
                v-model="lightMode"
                inset
                hide-details
                :ripple="false"
                color="text"
                title="Toggle dark mode"
                :prepend-icon="'mdi-weather-night'"
              >
                <template  v-slot:label>
                  <v-icon  color="text">mdi-weather-sunny</v-icon>
                </template>
              </v-switch>

              <v-select
                v-if="isDashboard"
                class="header__select"
                :class="{'float-right' : $vuetify.breakpoint.xs}"
                :value="this.$store.state.days"
                :items="items"
                @change="changeDays"
                :disabled="this.$store.state.isLoading"
                label="Time range"
                outlined
                hide-details
                dense
              ></v-select>
            </div >
          </v-row>
          <v-row v-if="isDashboard && $vuetify.breakpoint.mdAndUp" class="header__down-info">
            <p class="logo__update mb-0 ">{{this.$store.state.lastUpdate ? 'Updated: ' + this.$store.state.lastUpdate.format('MMMM Do YYYY, HH:mm z') : '' }}</p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>

/**
 * @property {OptionsManager} optionsManager
 */
export default {
  inject: ['optionsManager'],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      darkMode: this.optionsManager.getDarkMode(),
      items: [
        { text: '7d', value: 7 },
        { text: '14d', value: 14 },
        { text: '1m', value: 30 },
        { text: '3m', value: 90 },
        { text: '6m', value: 180 },
        { text: 'Max.', value: 0 }
      ],
      previousScroll: window.scrollY,
      scrollDirection: ''
    }
  },
  computed: {
    isDashboard () {
      return this.$route.name === 'dashboard'
    },
    headerHeight () {
      if (this.isDashboard && this.$vuetify.breakpoint.width < 600 && this.$vuetify.breakpoint.width > 385) {
        return 100
      } else if (this.isDashboard && this.$vuetify.breakpoint.width <= 385) {
        return 175
      }

      return 140
    },
    lightMode: {
      get () {
        return !this.optionsManager.getDarkMode()
      },
      set () {
        this.toggleDarkMode()
      }
    }
  },
  methods: {
    toggleDarkMode () {
      this.darkMode = this.optionsManager.toggleDarkMode()

      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Toggle light / night mode', {
          event_category: 'Header',
          event_label: this.darkMode
        })
        this.$gtag.event('Toggle light / night mode (count)', {
          event_category: 'Header',
          event_label: 'total'
        })
      }
    },
    changeDays (days) {
      const items = { 7: '7 days', 14: '14 days', 30: '1 months', 90: '3 months', 180: '6 months', 0: 'max.' }
      this.$store.commit('changeDays', days)

      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Change Time Range Selector', {
          event_category: 'Header',
          event_label: items[days]
        })
        this.$gtag.event('Change Time Range Selector (count)', {
          event_category: 'Header',
          event_label: 'total'
        })
      }
    },
    scrollClasses () {
      const newScroll = window.scrollY
      if (newScroll < 100) {
        this.scrollDirection = ''
      } else if (newScroll > this.previousScroll) {
        this.scrollDirection = 'down'
      } else if (newScroll < this.previousScroll) {
        this.scrollDirection = 'up'
      }

      this.previousScroll = newScroll
    }
  },
  created () {
    window.addEventListener('scroll', () => {
      this.scrollClasses()
    })
  }
}
</script>

<style lang="scss" scoped>
$xxs: 385px;
.header {
  &.v-app-bar.v-app-bar--fixed{
    z-index: 1000;

    &.v-app-bar--is-scrolled-down {
      @media #{map-get($display-breakpoints, 'xs-only')}{
        /* override inline-style */
        transform: translateY(-110%) !important;
      }
    }
  }

  &__main-info {
    gap: 10px;

    @media screen and (min-width: $xxs){
      flex-direction: row !important;
    }
  }

  &__right-info {
    gap: 40px;

    @media #{map-get($display-breakpoints, 'md-and-down')}{
      gap: 30px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')}{
      gap: 20px;
    }

    @media #{map-get($display-breakpoints, 'xs-only')}{
      gap: 0px;
    }
  }

  &__down-info {
    justify-content: flex-end;
  }

  &__select {
    max-width: 100%;

    @media screen and (min-width: $xxs){

      max-width: 100px;
    }
  }

  .container {
    @media #{map-get($display-breakpoints, 'md-only')}{
      max-width: 100%;
    }
  }

  .logo__update{
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }

  .v-toolbar__title .logo {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    max-width: 100%;

    &__title{
      width: calc(100% - 80px);
      font-weight: 700;
      font-size: 22px;
      line-height: 130%;

      @media #{map-get($display-breakpoints, 'sm-only')}{
        font-size: 24px;
        word-wrap: normal;
      }

      @media #{map-get($display-breakpoints, 'xs-only')}{
        font-size: 32px;
        width: auto;
      }
    }

    &__icon {
      .logo-icon {
        width: 100%;
      }

      @media #{map-get($display-breakpoints, 'xs-only')}{
        width: 50px;
        .logo-icon {
          height: auto;
        }
      }
    }

    &__subtitle {
      font-size: 12px;
      margin-bottom: 5px;
    }

    &__update{
      font-size: 16px;

      @media #{map-get($display-breakpoints, 'sm-only')}{
        font-size: 14px;
      }
    }
  }

  ::v-deep {
    .v-toolbar__content {
      @media #{map-get($display-breakpoints, 'sm-and-down')}{
        margin-top: -10px;
      }

      @media #{map-get($display-breakpoints, 'xs-only')}{
        margin-top: -20px;
      }
    }
  }

  &.dashboard {
    .header {
      &__left-info {
        @media screen and (min-width: $xxs){
          max-width: calc(100% - 130px);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.v-input__slot .v-input--selection-controls__input,
.v-icon > svg{
    width: 50px;
    margin: 0;
}
</style>
