<template>
  <div>
    <FullHeader />
    <v-main>
      <v-container fluid class="page-wrapper">
        <Transition name="router-fade" mode="out-in">
          <router-view />
        </Transition>
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import FullHeader from './Header'
import Footer from './Footer'

export default {
  components: {
    FullHeader,
    Footer
  }
}
</script>

<style scoped>
.router-fade-enter-active,
.router-fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.router-fade-enter-from,
.router-fade-leave-to {
  opacity: 0;
}
</style>
