<template>
  <v-container fluid class="footer mb-10">
    <v-container :fluid="$vuetify.breakpoint.md">
      <v-row class="background darken-1 d-flex flex-row align-center justify-center mx-0">
        <v-col cols="12" lg="10" md="10">
          <v-card  flat class="transparent pa-md-10  pa-5" >
            <p class="text-h4 text-center font-weight-bold">Discover how World Mobile is rebooting a trillion-dollar industry</p>
            <v-container fluid class="d-flex flex-column align-center justify-center mt-7">
              <div class="footer__icons mt-md-0 mt-10 mr-md-10">
                <v-btn
                  href="https://twitter.com/WorldMobileTeam"
                  target="_blank"
                  rel="noreferrer noopener"
                  @click="clickBtn('Twitter')"
                  icon
                  plain
                >
                  <v-icon x-large>mdi-twitter</v-icon>
                </v-btn>
                <v-btn
                  href="https://bit.ly/wmjoinus"
                  target="_blank"
                  rel="noreferrer noopener"
                  @click="clickBtn('Telegram')"
                  icon
                  plain
                >
                  <v-icon x-large>$telegram</v-icon>
                </v-btn>
                <v-btn
                  href="https://discord.gg/worldmobile"
                  target="_blank"
                  rel="noreferrer noopener"
                  @click="clickBtn('Discord')"
                  icon
                  plain
                >
                  <v-icon x-large>$discord</v-icon>
                </v-btn>
              </div>
              <div class="d-flex flex-column flex-sm-row footer__links mt-10 align-center justify-center">
                <a
                  large
                  color="primary"
                  href="https://www.worldmobile.io/"
                  @click="clickBtn('World Mobile Home')"
                  target="_blank"
                  rel="noreferrer noopener"
                  exact-path
                  class="ml-md-10 footer__button--io text-h6"
                >
                  WorldMobile.io
                </a>
                <a
                  large
                  color="primary"
                  href="https://www.worldmobiletoken.com/"
                  @click="clickBtn('World Mobile Token')"
                  target="_blank"
                  rel="noreferrer noopener"
                  exact-path
                  class="ml-0 ml-sm-10 mt-2 mt-sm-0 footer__button--token text-h6"
                >
                  WorldMobileToken.com
                </a>
              </div>
              </v-container>

            <v-container class="mt-10 text-center">
              <span class="text-p">Check out our <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link> and <router-link :to="{ name: 'terms-and-conditions' }">Terms and Conditions</router-link> and our <router-link :to="{ name: 'cookies' }">Cookies Policy</router-link>.</span>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    clickBtn (name) {
      if (this.$cookies.list['google-analytics']) {
        this.$gtag.event('Click Button', {
          event_category: 'Footer',
          event_label: name
        })
      }
    }
  }
}
</script>

<style lang="scss">
.footer {
  z-index: 1000;

  &__icons {
    display: flex;
    gap: 30px;
  }

  a {
    text-decoration: none;
  }

  &__links {
    a {
      text-transform: uppercase;
      color: var(--v-blue-base);
    }

  }

}
</style>
